$(document).on('turbolinks:load', function(){

    $('#tenant-modal').on('shown.bs.modal', function() {

        // On modal show
        displayDefault();


        $("#tenant_tenant_type_id").change( function(event) {
            var tenantType = $('#tenant_tenant_type_id').find(":selected").text();
       

            if (tenantType == "Particulier"){
                $("#particular-fields").css("display", "block");
                $("#company-fields").css("display", "none");
            }
            else if(tenantType == "Société"){
                 $("#particular-fields").css("display", "none");
                $("#company-fields").css("display", "block");
            }
            else{
                $("#particular-fields").css("display", "none");
                $("#company-fields").css("display", "none");

            }

        });

        
    });



})




function displayDefault(){
    var tenantType = $('#tenant_tenant_type_id').find(":selected").text();

    if (tenantType == "Particulier"){

        $("#particular-fields").css("display", "block");
        $("#company-fields").css("display", "none");
    }
    else if (tenantType == "Société"){

        $("#particular-fields").css("display", "none");
        $("#company-fields").css("display", "block");
    }
    else {

        $("#particular-fields").css("display", "none");
        $("#company-fields").css("display", "none");

    }
}