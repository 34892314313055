$(document).on('turbolinks:load', function(){

    $('#property-modal').on('shown.bs.modal', function() {
        displayBuildings();
        
        $("#property_property_type_id").on('change', function(){
            displayBuildings();
        });



        function displayBuildings(){


            property_type = document.getElementById("property_property_type_id")
            property_type = property_type.options[property_type.selectedIndex].text;
           
            if (property_type != null && property_type == "Immeuble"){
                $(".buildings").css("display", "block");
                
            }
            else{
                $(".buildings").css("display", "none");
        
            }
        }
    });

});






$(document).on('turbolinks:load', function(){


   $('#property-modal').on('shown.bs.modal', function() {
    
        ajaxRequest("#property_landlord_id", "/properties/get_buildings", "GET");
    });


   

});
