$(document).on('turbolinks:load', function(){

    $('#order-modal').on('shown.bs.modal', function() {

        
        $("#order_tenant_id").change( function(event) {
            console.log("ORDER TENANT");
            ajaxRequest("#order_tenant_id", "/orders/properties", "GET");
            ajaxRequest("#order_property_id", "/orders/property", "GET");

        });


        
    });



})


