$(document).on('turbolinks:load', function(){

    $('#income-modal').on('shown.bs.modal', function() {

        // On modal show
        displayDefault();


        $("#income_income_type_id").change( function(event) {
            
            var incomeType = $('#income_income_type_id').find(":selected").text();

            if (incomeType == "Loyer"){
               $("#income_start_date").prop("disabled", false);
                $("#income_end_date").prop('disabled', false);
            }
            else{
                

                $("#income_start_date").prop("disabled", true);
                $("#income_end_date").prop('disabled', true);

            }

        });


        //
        $("#income_tenant_id").change( function(event) {
            ajaxRequest("#income_tenant_id", "/incomes/properties", "GET");
            ajaxRequest("#income_property_id", "/incomes/property", "GET");

        });


        
    });



})




function displayDefault(){
    
    var incomeType = $('#income_income_type_id').find(":selected").text();

    if (incomeType == "Loyer"){
        $("#income_start_date").prop("disabled", false);
        $("#income_end_date").prop('disabled', false);
        
    }
    else {

        

        $("#income_start_date").prop("disabled", true);
        $("#income_end_date").prop('disabled', true);

    }
}