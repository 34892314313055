$(document).on('turbolinks:load', function(){

    $('#landlord-modal').on('shown.bs.modal', function() {

        // On modal show
        displayDefault();


        $("#landlord_landlord_type_id").change( function(event) {
            var landlordType = $('#landlord_landlord_type_id').find(":selected").text();
            console.log("CHANGED: ", landlordType);

            if (landlordType == "Particulier"){
                $("#particular-fields").css("display", "block");
                $("#company-fields").css("display", "none");
            }
            else if(landlordType == "Société"){
                 $("#particular-fields").css("display", "none");
                $("#company-fields").css("display", "block");
            }
            else{
                $("#particular-fields").css("display", "none");
                $("#company-fields").css("display", "none");

            }

        });

        
    });



})




function displayDefault(){
    var landlordType = $('#landlord_landlord_type_id').find(":selected").text();

    if (landlordType == "Particulier"){

        $("#particular-fields").css("display", "block");
        $("#company-fields").css("display", "none");
    }
    else if (landlordType == "Société"){

        $("#particular-fields").css("display", "none");
        $("#company-fields").css("display", "block");
    }
    else {

        $("#particular-fields").css("display", "none");
        $("#company-fields").css("display", "none");

    }
}